<template>
  <v-img
    id="default-image"
    :src="imageSrc"
    :height="height"
  />
</template>

<script>
export default {
  name: 'DefaultImage',
  props: {
    asset: {
      type: Object,
    },
    height: {
      type: [Number, String],
    },
    blur: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      if (this.blur) {
        return require('@/assets/img/mainBlur.jpg');
      }
      return require('@/assets/img/defaultAsset.jpg');
    },
  },
};
</script>

<style lang="scss">
.blur-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
